import http from './http';

export function findRequestById(id, include = []) {
    return http.get(`/requests/${id}`, {
        params: { include: include.join(',') },
    });
}

export function findRequest(id) {
    return http.post(`/requests/view/${id}`);
}

export function getRequestHistory(id) {
    return http.get(`/requests/${id}/history`);
}

export function getRequestObservers(id) {
    return http.get(`/requests/${id}/observers`);
}

export function changeRequestService(id, request) {
    return http.patch(`/requests/${id}/service`, request);
}

export function changeRequestExecutor(id, request) {
    return http.patch(`/requests/${id}/executor`, request);
}

export function changeRequestStatus(id, status, params = {}) {
    return http.patch(`/requests/${id}/status`, {
        status,
        params,
    });
}

export function addRequestComment(id, request) {
    return http.post(`/requests/${id}/comments`, request);
}

export function editRequestComment(id, commentId, newText) {
    return http.patch(`/requests/${id}/comments/${commentId}`, { text: newText });
}

export function addRequestObserver(id, request) {
    return http.post(`/requests/${id}/observers`, request);
}

export function deleteRequestObserver(id, observerId) {
    return http.delete(`/requests/${id}/observers/${observerId}`);
}

export function getUserGroupsFromBitrix24() {
    return http.get(`/bitrix24/user/groups`);
}

export function createTaskInBitrix24FromRequest(groupId, params) {
    return http.post(`/bitrix24/groups/${groupId}/tasks`, params);
}

export function getLastReadComment(request, lastComment) {
    return http.post(`/requests/${request.id}/mark-as-read`, { comment_id: lastComment.id });
}

export function getCities(limit = 15) {
    return http.get(`/cities`, { params: { limit } });
}
