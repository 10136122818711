<template>
    <VModalWrapper :title="title" :is-loading="isLoading" v-on="listeners" v-bind="attributes">
        <ValidationObserver v-slot="{ handleSubmit }">
            <form class="active-form" @submit.prevent="handleSubmit(submit)">
                <ValidationProvider
                    class="active-form__field"
                    name="displayName"
                    rules="required|max:256"
                    v-slot="{ errors }"
                >
                    <label class="active-form__label active-form__label_required" for="user-displayName">Имя</label>

                    <VInput
                        id="user-displayName"
                        name="displayName"
                        class="active-form__control"
                        :class="{
                            error: errors[0],
                        }"
                        @submit="handleSubmit(submit)"
                        v-model="user.displayName"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider
                    class="active-form__field"
                    name="roles"
                    rules="required|max:128"
                    v-slot="{ errors }"
                >
                    <label class="active-form__label active-form__label_required" for="user-roles">Роли</label>

                    <VSelect
                        id="user-roles"
                        name="roles"
                        class="active-form__control"
                        multiple
                        :class="{
                            error: errors[0],
                        }"
                        :options="roles"
                        @submit="handleSubmit(submit)"
                        v-model="user.roles"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider
                    class="active-form__field"
                    name="email"
                    rules="required|email|max:256"
                    v-slot="{ errors }"
                >
                    <label class="active-form__label active-form__label_required" for="user-email">E-mail</label>

                    <VInput
                        id="user-email"
                        name="email"
                        class="active-form__control"
                        :class="{
                            error: errors[0],
                        }"
                        @submit="handleSubmit(submit)"
                        v-model="user.email"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider class="active-form__field" name="company" rules="max:128" v-slot="{ errors }">
                    <label class="active-form__label" for="user-company">Компания</label>

                    <VInput
                        id="user-company"
                        name="company"
                        class="active-form__control"
                        :class="{
                            error: errors[0],
                        }"
                        @submit="handleSubmit(submit)"
                        v-model="user.company"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider class="active-form__field" name="department" rules="max:128" v-slot="{ errors }">
                    <label class="active-form__label" for="user-department">Отдел</label>

                    <VInput
                        id="user-department"
                        name="department"
                        class="active-form__control"
                        :class="{
                            error: errors[0],
                        }"
                        @submit="handleSubmit(submit)"
                        v-model="user.department"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider class="active-form__field" name="title" rules="max:256" v-slot="{ errors }">
                    <label class="active-form__label" for="user-title">Должность</label>

                    <VInput
                        id="user-title"
                        name="title"
                        class="active-form__control"
                        :class="{
                            error: errors[0],
                        }"
                        @submit="handleSubmit(submit)"
                        v-model="user.title"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider class="active-form__field" name="telephone" rules="max:256" v-slot="{ errors }">
                    <label class="active-form__label" for="user-telephone">Рабочий тел.</label>

                    <VInput
                        id="user-telephone"
                        name="telephone"
                        class="active-form__control"
                        :class="{
                            error: errors[0],
                        }"
                        @submit="handleSubmit(submit)"
                        v-model="user.telephone"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider class="active-form__field" name="mobile" rules="max:24" v-slot="{ errors }">
                    <label class="active-form__label" for="user-mobile">Мобильный тел.</label>

                    <VInput
                        id="user-mobile"
                        name="mobile"
                        class="active-form__control"
                        :class="{
                            error: errors[0],
                        }"
                        @submit="handleSubmit(submit)"
                        v-model="user.mobile"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider
                    class="active-form__field"
                    name="location"
                    rules="required|max:128"
                    v-slot="{ errors }"
                >
                    <label class="active-form__label active-form__label_required" for="user-location">Город</label>

                    <VSelect
                        id="user-location"
                        name="location"
                        label="name"
                        class="active-form__control"
                        :class="{
                            error: errors[0],
                        }"
                        :options="cities"
                        :reduce="(x) => x.name"
                        @submit="handleSubmit(submit)"
                        v-model="user.location"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <div class="active-form__field">
                    <label class="active-form__label" for="user-location">Часовой пояс</label>

                    <VInput id="user-location" class="active-form__control" disabled v-model="user.timezone" />
                </div>

                <div class="active-form__actions">
                    <VButton type="button" class="active-form__action button_green-light" @click="hide">
                        Отмена
                    </VButton>

                    <VButton type="submit" class="active-form__action button_green"> Сохранить </VButton>
                </div>
            </form>
        </ValidationObserver>
    </VModalWrapper>
</template>
<script>
// Components
import VInput from '../components/VInput/VInput.vue';
import VButton from '../components/VButton/VButton.vue';
import VSelect from '../components/VSelect/VSelect.vue';
import VModalWrapper from '../components/VModalWrapper/VModalWrapper.vue';
import VContenteditable from '../components/VContenteditable/VContenteditable.vue';

import { ValidationObserver, ValidationProvider } from 'vee-validate';

// Other
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ModalNames from '../../js/enums/ModalNames';
import ModalMixin from '../../js/vue/mixins/ModalMixin';
import { getCities } from '../../js/api';
import AccountRoleType from '../../js/enums/AccountRoleType';

@Component({
    components: {
        VInput,
        VButton,
        VSelect,
        VModalWrapper,
        VContenteditable,

        ValidationObserver,
        ValidationProvider,
    },
})
class VAdminUserCard extends ModalMixin {
    @Prop({ type: String, default: ModalNames.ADMIN_PANEL_USER_CARD })
    name;
    @Prop({ type: String, default: 'Карточка пользователя' })
    defaultTitle;

    user = null;
    roles = [
        { role: AccountRoleType.USER, label: 'Пользователь' },
        { role: AccountRoleType.EXECUTOR, label: 'Исполнитель' },
        { role: AccountRoleType.ADMIN, label: 'Администратор' },
    ];
    cities = [];
    isLoading = true;

    submit() {
        this.resolve(this.user);

        this.hide();
    }

    async fetchCities() {
        if (!this.cities?.length) {
            const response = await getCities(10000);
            this.cities = response.data.data;
        }
    }

    async beforeOpen(event) {
        this.user = event?.params?.user;

        this.user.roles = this.user.roles.map((role) => {
            const label = this.roles.find((item) => item.role === role.role).label;

            return {
                role: role.role,
                label,
            };
        });

        this.isLoading = true;

        try {
            await this.fetchCities();
        } finally {
            this.isLoading = false;
        }
    }

    beforeClose() {
        this.user = null;
    }
}

export default VAdminUserCard;
</script>
