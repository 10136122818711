<template>
    <transition name="animation-fadedown">
        <div class="v-filter">
            <template v-if="checkRequestFilterSettings()">
                <div class="v-filter__settings">
                    <ToggleButtonLink v-model="showSettingsFilter">Настройка фильтра</ToggleButtonLink>

                    <transition name="animation-fadedown">
                        <div class="v-filter__settings v-filter__settings_group" v-if="showSettingsFilter">
                            <template v-for="(group, i) in filterGroups">
                                <ul :key="i" class="v-filter__settings-items">
                                    <li :key="filter.name" class="v-filter__settings-item" v-for="filter in group">
                                        <InputCheckbox
                                            :name="filter.name"
                                            @input="onCheckboxChange"
                                            v-model="filter.active"
                                            >{{ filter.title }}</InputCheckbox
                                        >
                                    </li>
                                </ul>
                            </template>
                        </div>
                    </transition>
                </div>

                <div class="v-filter__filters">
                    <ul class="v-filter__filters-items">
                        <transition-group name="animation-fadedown">
                            <template v-for="filter in activeFilters">
                                <li :key="filter.name" class="v-filter__filters-item">
                                    <div class="v-filter__component">
                                        <div class="v-filter__component-name">{{ filter.title }}:</div>

                                        <div class="v-filter__component-value">
                                            <component :is="getFilterName(filter.name)" :ref="filter.name"></component>
                                        </div>
                                    </div>
                                </li>
                            </template>
                        </transition-group>
                    </ul>
                </div>

                <div class="v-filter__control">
                    <VButton @click.prevent="onApply" class="button_green">Применить</VButton>

                    <VButton @click.prevent="onReset" class="button_orange button_space-left">Сбросить</VButton>
                </div>
            </template>

            <template v-else>
                <div class="v-filter__loading">
                    <VSpinner></VSpinner>
                </div>
            </template>
        </div>
    </transition>
</template>
<script>
import { namespace } from 'vuex-class';
import { Vue, Component, Emit } from 'vue-property-decorator';

import VSpinner from '@/views/components/VProgress/VSpinner';
import InputCheckbox from '@/views/components/VCheckbox/InputCheckbox';
import VButton from '@/views/components/VButton/VButton.vue';
import ToggleButtonLink from '@/views/components/VButton/ToggleButtonLink';

import Filters from './Filters';
import upperFirst from 'lodash/upperFirst';
import { isNumber } from 'validate.js';

const Account = namespace('account');

@Component({
    components: {
        VButton,
        VSpinner,
        InputCheckbox,
        ToggleButtonLink,
        ...Filters,
    },
})
class VFilter extends Vue {
    @Account.Action CREATE_REQUEST_FILTER_SETTINGS;
    @Account.Action UPDATE_REQUEST_FILTER_SETTINGS;

    filterGroups = [];
    showSettingsFilter = false;
    filterSettingUpdate = false;

    get filters() {
        return this.filterGroups.reduce((carry, group) => {
            carry.push(...group);
            return carry;
        }, []);
    }

    get activeFilters() {
        return this.filters.filter((filter) => filter.active);
    }

    @Emit('change')
    onApply() {
        return this.getValueByActiveFilters();
    }

    @Emit('reset')
    onReset() {
        this.resetActiveFilters();
        return [];
    }

    onCheckboxChange() {
        if (this.filterSettingUpdate) return;

        this.filterSettingUpdate = true;

        setTimeout(() => {
            this.filterSettingUpdate = false;
            this.updateRequestFilterSettings();
        }, 5000);
    }

    getValueByActiveFilters() {
        return this.activeFilters.reduce((carry, filter) => {
            const value = this.getFilterByName(filter.name).getValue();

            if ((value && value.length) || isNumber(value) || value === 0) {
                carry[filter.name] = value;
            }

            return carry;
        }, {});
    }

    getFilterName(name) {
        return upperFirst(name);
    }

    getFilterByName(name) {
        return this.$refs[name][0];
    }

    getFilterSettings() {
        return this.filters.reduce((carry, filter) => {
            carry[filter.name] = filter.active;
            return carry;
        }, {});
    }

    resetActiveFilters() {
        this.activeFilters.forEach((filter) => this.getFilterByName(filter.name).reset());
    }

    loadDefaultFilterSettings() {
        this.filterGroups = Object.values(this.$config.filterGroups);
    }

    loadAccountFilterSettings() {
        this.filterGroups = Object.values(this.$config.filterGroups).map((filters) =>
            filters.map((filter) => {
                filter.active = !!this.$_account.requestFilterSettings[filter.name];

                return filter;
            }),
        );
    }

    checkRequestFilterSettings() {
        return !!this.$_account.requestFilterSettings;
    }

    createRequestFilterSettings() {
        this.CREATE_REQUEST_FILTER_SETTINGS(this.getFilterSettings());
    }

    updateRequestFilterSettings() {
        this.UPDATE_REQUEST_FILTER_SETTINGS({
            requestFilterSettings: this.getFilterSettings(),
            requestFilterSettingsId: this.$_account.requestFilterSettings.id,
        });
    }

    created() {
        if (!this.checkRequestFilterSettings()) {
            this.loadDefaultFilterSettings();
            this.createRequestFilterSettings();
        } else {
            this.loadAccountFilterSettings();
        }
        this.$bus.on('tools:clear', this.resetActiveFilters);
    }

    beforeDestroy() {
        this.$bus.off('tools:clear', this.resetActiveFilters);
    }
}

export default VFilter;
</script>
<style lang="scss">
.v-filter {
    display: flex;
    width: 100%;
    flex-direction: column;

    &__loading {
        display: flex;
        width: 100%;
        height: 120px;
        align-items: center;
        justify-content: center;
    }

    &__settings,
    &__filters {
        display: flex;
        padding: 5px 0;
        flex-direction: column;

        &-item {
            padding: 2px 3px;
        }

        &-items {
            flex: 1;
            margin: 0;
            padding: 4px;
            list-style-type: none;
        }

        &_group {
            flex-direction: row;
        }
    }

    &__control {
        display: flex;
        padding: 5px 0;
        justify-content: flex-end;
    }

    &__button {
        color: var(--color-white);
        margin-left: 8px;
    }

    &__component {
        display: flex;

        &-name {
            display: flex;
            width: 20%;
            padding: 0 15px;
            align-self: center;
            justify-content: flex-end;
            font-size: 0.9rem;
        }

        &-value {
            width: 80%;
            padding: 0 5px;
        }

        &_group {
            flex-direction: column;

            &-item {
                padding: 2px 0;
            }
        }
    }
}
@media(max-width: $mobile-breakpoint){
    .v-filter{
        &__component-name{
            font-size: 0.77rem;
            padding: 0 5px;
        }
        &__control{
            .button{
                font: 400 0.77rem Roboto;
                padding: 8px;
            }
        }
        &__settings-item{
            .form__label_checkbox{
                font-size: 0.77rem;
            }
        }
    }
}
</style>
