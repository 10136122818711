var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VModalWrapper',_vm._g(_vm._b({attrs:{"title":_vm.title,"is-loading":_vm.isLoading}},'VModalWrapper',_vm.attributes,false),_vm.listeners),[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{staticClass:"active-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"label","rules":"max:128"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label active-form__label_required",attrs:{"for":"city-label"}},[_vm._v("Название")]),_c('VInput',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"city-label","name":"label"},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.city.name),callback:function ($$v) {_vm.$set(_vm.city, "name", $$v)},expression:"city.name"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"timezone","rules":"required|max:128"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label active-form__label_required",attrs:{"for":"city-timezone"}},[_vm._v("Часовой пояс")]),_c('VSelect',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"city-timezone","name":"timezone","label":"name","options":_vm.timezones},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.city.timezone),callback:function ($$v) {_vm.$set(_vm.city, "timezone", $$v)},expression:"city.timezone"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"defaultSecurityGroupId"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label",attrs:{"for":"city-defaultSecurityGroupId"}},[_vm._v("Группа ответственных")]),_c('VSelect',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"security-group-observers","name":"defaultSecurityGroupId","label":"name","options":_vm.securityGroups,"reduce":(x) => x.id},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.city.defaultSecurityGroupId),callback:function ($$v) {_vm.$set(_vm.city, "defaultSecurityGroupId", $$v)},expression:"city.defaultSecurityGroupId"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"defaultExecutorId"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label",attrs:{"for":"city-defaultExecutorId"}},[_vm._v("Исполнитель")]),_c('VSelect',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"security-group-observers","name":"defaultExecutorId","label":"displayName","reduce":(x) => x.id,"options":_vm.securityGroup?.accounts ?? [],"resetOnOptionsChange":true},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.city.defaultExecutorId),callback:function ($$v) {_vm.$set(_vm.city, "defaultExecutorId", $$v)},expression:"city.defaultExecutorId"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('div',{staticClass:"active-form__actions"},[_c('VButton',{staticClass:"active-form__action button_green-light",attrs:{"type":"button"},on:{"click":_vm.hide}},[_vm._v(" Отмена ")]),_c('VButton',{staticClass:"active-form__action button_green",attrs:{"type":"submit"}},[_vm._v(" Сохранить ")])],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }