<template>
    <div class="app-layout">
        <div :data-theme="theme" class="app-layout__body">
            <div class="app-layout__wrapper">

                <VAppMain class="app-layout__row app-layout__row_main">
                    <slot></slot>
                </VAppMain>
            </div>

            <VSnowfall :isSnowEnabled="isSnowEnabled"></VSnowfall>
        </div>
    </div>
</template>
<script>
import './VApp.scss';

import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import VAppMain from './VAppMain.vue';
import VAppSidebar from './VAppSidebar.vue';
import VSnowfall from '../VFun/VSnowfall.vue';

@Component({
    components: {
        VSnowfall,
        VAppMain,
        VAppSidebar,
    },
})
class AppLayout extends Vue {
    @Prop({ type: Boolean })
    isSnowEnabled;

    date = new Date();
    theme = 'light';

    toggleTheme() {
        this.theme = this.theme === 'light' ? 'dark' : 'light';
    }

    get year() {
        return this.date.getFullYear();
    }
}

export default AppLayout;
</script>
