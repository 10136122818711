<template>
    <div ref="dropzone" class="dropzone" v-show="isFileDrag">
        <div class="dropzone__area">
            <span class="text-xxl">{{ message }}</span>
        </div>
    </div>
</template>
<script>

import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import { isFileDrag } from '../../../js/dragEventEmitter';

@Component
class VDropzone extends Vue {
    @Prop({ type: String, default: '' })
    message;

    @Prop({ type: Boolean, default: false })
    allowUploadDocuments;

    @Ref('dropzone')
    dropzone;

    isFileDrag = false;

    filesInWindowHandler(event) {
        if (this.isContainsOnlyFilesTypes(event)) {
            this.isFileDrag= true;
        }
    }

    filesOutWindowHandler() {
        this.isFileDrag = false;
    }

    dropHandler(event) {
        const dataTransfer = event.dataTransfer;
        this.$emit('dropFiles', dataTransfer);
    }

    isContainsOnlyFilesTypes(event) {
        const dataTransfer = event.dataTransfer;

        if (dataTransfer?.types?.length) {
            for (const type of dataTransfer.types) {
                if (type !== 'Files') {
                    return false;
                }
            }

            return true;
        }

        return false;
    }

    mounted() {
        this.isFileDrag = isFileDrag;
        if (this.dropzone) {
            this.dropzone.addEventListener('drop', this.dropHandler, false);
        }

        this.$bus.on('Dropzone:FilesInWindow', this.filesInWindowHandler);
        this.$bus.on('Dropzone:FilesOutWindow', this.filesOutWindowHandler);
    }
}
export default VDropzone;
</script>
<style lang="scss">
.dropzone {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 5px;
    z-index: 100;

    &__area {
        display: flex;
        width: 100%;
        height: 100%;
        border-radius: 15px;
        border: 4px dashed $green;
        background-color: rgba(227, 254, 236);
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        color: $green;
    }
}
</style>
