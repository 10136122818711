<template>
    <div class="tools-sort">
        <VButton
            class="button_orange button_small-text button_space-right tools-sort__button"
            @click="$emit('toggleFilter')"
            >Фильтр</VButton
        >
        <VButton class="button_orange button_small-text tools-sort__button" @click="$emit('toggleSettings')"
            >Настройки</VButton
        >

        <div class="tools-sort__buttons-right">
            <VButton
                class="button_small-text button_space-left tools-sort__button"
                :class="{
                    button_green: isTarget(target),
                    button_orange: !isTarget(target),
                }"
                :key="i"
                @click="sort(target)"
                v-for="(target, i) in targets"
            >
                {{ target.title }}

                <template v-slot:icon>
                    <SortAscending class="tools-sort__icon" :size="15" v-if="isSortUp(target)"></SortAscending>

                    <SortDescending class="tools-sort__icon" :size="15" v-if="isSortDown(target)"></SortDescending>
                </template>
            </VButton>
        </div>
    </div>
</template>
<script>
import types from '@/js/enums/RequestSortType';
import titles from '@/js/enums/RequestSortTitle';
import VButton from '@/views/components/VButton/VButton.vue';

import SortAscending from 'vue-material-design-icons/SortAscending.vue';
import SortDescending from 'vue-material-design-icons/SortDescending.vue';

export default {
    components: {
        VButton,
        SortAscending,
        SortDescending,
    },
    data: () => ({
        reverse: true,
        targetType: null,
        targets: [
            {
                type: types.DATE,
                title: titles[types.DATE],
            },
            {
                type: types.DEADLINE,
                title: titles[types.DEADLINE],
            },
            {
                type: types.ACTIVITY,
                title: titles[types.ACTIVITY],
            },
        ],
    }),
    methods: {
        sort(target) {
            this.reverse = this.targetType === target.type ? (this.reverse = !this.reverse) : true;
            this.targetType = target.type;

            this.$emit('sort', {
                type: target.type,
                reverse: this.reverse,
            });
        },
        clear() {
            this.reverse = true;
            this.targetType = types.DATE;
        },
    },
    computed: {
        isSortUp() {
            return (target) => target.type === this.targetType && this.reverse;
        },
        isSortDown() {
            return (target) => target.type === this.targetType && !this.reverse;
        },
        isTarget() {
            return (target) => target.type === this.targetType;
        },
    },
    created() {
        this.$bus.on('tools:clear', this.clear);
        this.$bus.on('sort:clear', this.clear);
    },
    mounted() {
        this.sort(this.targets[0]);
    },
    beforeDestroy() {
        this.$bus.off('tools:clear', this.clear);
        this.$bus.off('sort:clear', this.clear);
    },
};
</script>
<style lang="scss">
.tools-sort {
    display: flex;

    &__icon {
        color: #efefef;
        margin-left: 5px;
    }

    &__button {
        display: flex;
        padding: 5px 15px;
        flex-direction: row-reverse;
        border-radius: 4px;
        box-sizing: content-box;
    }

    &__buttons-right {
        display: flex;
        margin-left: auto;
    }
}
@media(max-width: $mobile-breakpoint){
    .tools-sort__button {
        padding: 5px;
        font-size: 0.7rem;
    }
}
</style>
