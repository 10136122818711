var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"request-comment request-comment_light",class:{
        'request-comment_dark': _vm.isFirstComment,
        'request-comment_border-light': _vm.isUserAuthorOfComment,
    }},[_c('div',{staticClass:"request-comment__header"},[_c('UserCardWithPopover',{attrs:{"account":_vm.comment.author,"status":_vm.$_toLocalDate(_vm.comment.createdAt)}})],1),(_vm.chunks && _vm.chunks.length > 0)?_c('p',{staticClass:"request-comment__content"},[_vm._l((_vm.chunks),function(chunk,index){return [(chunk)?_c('span',{directives:[{name:"linkified",rawName:"v-linkified:options",value:({
                    validate: _vm.validate,
                    defaultProtocol: 'https',
                }),expression:"{\n                    validate: validate,\n                    defaultProtocol: 'https',\n                }",arg:"options"}],key:index,staticClass:"request-comment__content-chunk",domProps:{"textContent":_vm._s(chunk)}}):_vm._e(),_c('br',{key:index + '-br'})]})],2):_vm._e(),(_vm.comment.createdAt != _vm.comment.updatedAt)?_c('p',{staticClass:"request-comment__content-edited"},[_c('span',[_vm._v("(изменено)")])]):_vm._e(),(_vm.comment.files && _vm.comment.files.length > 0)?_c('ul',{staticClass:"request-comment__attachments"},[_vm._l((_vm.attachments),function(attachment,index){return [_c('li',{key:index,staticClass:"request-comment__attachment"},[_c('ChatCommentAttachment',{attrs:{"dark":_vm.isFirstComment,"attachment":attachment}})],1)]})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }