import Vue from 'vue';

let isFileDrag = false;

function dragEnterHandler(event) {
    event.preventDefault();
    event.stopPropagation();

    if (isFileDrag === false) {
        isFileDrag = true;

        Vue.bus.emit('Dropzone:FilesInWindow', event);
    }
}

function dragLeaveHandler(event) {
    event.preventDefault();
    event.stopPropagation();

    const isDrop = event.type === 'drop';
    const isOutsideWindow = event.clientX + event.clientY === 0;

    if (isDrop || isOutsideWindow) {
        isFileDrag = false;

        Vue.bus.emit('Dropzone:FilesOutWindow', event);
    }
}

document.addEventListener('dragover', dragEnterHandler, false);
document.addEventListener('dragenter', dragEnterHandler, false);

document.addEventListener('drop', dragLeaveHandler, false);
document.addEventListener('dragleave', dragLeaveHandler, false);
