<template>
    <VModalWrapper :title="title" :is-loading="isLoading" v-on="listeners" v-bind="attributes">
        <ValidationObserver v-slot="{ handleSubmit }">
            <form class="active-form" @submit.prevent="handleSubmit(submit)">
                <ValidationProvider class="active-form__field" name="label" rules="max:128" v-slot="{ errors }">
                    <label class="active-form__label active-form__label_required" for="city-label">Название</label>

                    <VInput
                        id="city-label"
                        name="label"
                        class="active-form__control"
                        :class="{
                            error: errors[0],
                        }"
                        @submit="handleSubmit(submit)"
                        v-model="city.name"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider
                    class="active-form__field"
                    name="timezone"
                    rules="required|max:128"
                    v-slot="{ errors }"
                >
                    <label class="active-form__label active-form__label_required" for="city-timezone"
                        >Часовой пояс</label
                    >

                    <VSelect
                        id="city-timezone"
                        name="timezone"
                        label="name"
                        class="active-form__control"
                        :class="{
                            error: errors[0],
                        }"
                        :options="timezones"
                        @submit="handleSubmit(submit)"
                        v-model="city.timezone"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider class="active-form__field" name="defaultSecurityGroupId" v-slot="{ errors }">
                    <label class="active-form__label" for="city-defaultSecurityGroupId">Группа ответственных</label>

                    <VSelect
                        id="security-group-observers"
                        name="defaultSecurityGroupId"
                        label="name"
                        class="active-form__control"
                        :class="{
                            error: errors[0],
                        }"
                        :options="securityGroups"
                        :reduce="(x) => x.id"
                        @submit="handleSubmit(submit)"
                        v-model="city.defaultSecurityGroupId"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider class="active-form__field" name="defaultExecutorId" v-slot="{ errors }">
                    <label class="active-form__label" for="city-defaultExecutorId">Исполнитель</label>

                    <VSelect
                        id="security-group-observers"
                        name="defaultExecutorId"
                        label="displayName"
                        class="active-form__control"
                        :class="{
                            error: errors[0],
                        }"
                        :reduce="(x) => x.id"
                        :options="securityGroup?.accounts ?? []"
                        :resetOnOptionsChange="true"
                        @submit="handleSubmit(submit)"
                        v-model="city.defaultExecutorId"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <div class="active-form__actions">
                    <VButton type="button" class="active-form__action button_green-light" @click="hide">
                        Отмена
                    </VButton>

                    <VButton type="submit" class="active-form__action button_green"> Сохранить </VButton>
                </div>
            </form>
        </ValidationObserver>
    </VModalWrapper>
</template>
<script>
// Components
import VInput from '../components/VInput/VInput.vue';
import VButton from '../components/VButton/VButton.vue';
import VSelect from '../components/VSelect/VSelect.vue';
import VModalWrapper from '../components/VModalWrapper/VModalWrapper.vue';
import VContenteditable from '../components/VContenteditable/VContenteditable.vue';

import { ValidationObserver, ValidationProvider } from 'vee-validate';

// Other
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ModalNames from '../../js/enums/ModalNames';
import ModalMixin from '../../js/vue/mixins/ModalMixin';
import { getAllTimezones, getAllSecurityGroups } from '../../js/admin-api';

@Component({
    components: {
        VInput,
        VButton,
        VSelect,
        VModalWrapper,
        VContenteditable,

        ValidationObserver,
        ValidationProvider,
    },
})
class VAdminCityCard extends ModalMixin {
    @Prop({ type: String, default: ModalNames.ADMIN_PANEL_CITY_CARD })
    name;
    @Prop({ type: String, default: 'Карточка города' })
    defaultTitle;

    city = null;
    isLoading = true;
    timezones = [];

    submit() {
        this.resolve(this.city);

        this.hide();
    }

    async fetchTimezones() {
        if (!this.timezones?.length) {
            const response = await getAllTimezones();
            this.timezones = response.data;
        }
    }

    async fetchSecurityGroups() {
        if (!this.securityGroups?.length) {
            const response = await getAllSecurityGroups(1, '', 100000);
            this.securityGroups = response.data.data.map((securityGroup) => ({
                id: securityGroup.id,
                name: securityGroup.name,
                accounts: securityGroup.accounts,
            }));
        }
    }

    get securityGroup() {
        if (!this.securityGroups || !this.city.defaultSecurityGroupId) {
            return null;
        }

        return this.securityGroups.find((securityGroup) => securityGroup.id === this.city.defaultSecurityGroupId);
    }

    async beforeOpen(event) {
        this.city = event?.params?.city;

        this.isLoading = true;

        try {
            await Promise.all([this.fetchTimezones(), this.fetchSecurityGroups()]);
        } finally {
            this.isLoading = false;
        }
    }

    beforeClose() {
        this.city = null;
    }
}

export default VAdminCityCard;
</script>
