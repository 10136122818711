<template>
    <div class="nav-menu">
        <div class="nav-menu-sidebar" :style="{display: (!isAdaptiveMenuOpen && isAdaptive) ? 'none' : 'flex'}">
            <div>
                <div class="nav-menu-sidebar__logo">
                    <RouterLink class="nav-menu-sidebar__logo-src" to="/"></RouterLink>
                    <VButton @click="closeMenu" class="button_close nav-menu-sidebar__logo-close">
                        <TimesSvg></TimesSvg>
                    </VButton>
                </div>
                <div class="nav-menu-sidebar__header">
                    <VButton class="button button_green button_fluid prevent-close" @click="showRequestCreateModal"
                        >Создать обращение</VButton
                    >
                </div>
                <div class="nav-menu-sidebar__list">
                    <ul>
                        <template v-for="(item, index) in items">
                            <li :key="index" class="nav-menu-sidebar__item" :class="item.class" @click="closeMenu">
                                <div class="nav-menu-sidebar__devider" v-show="index === 3"></div>
                                <RouterLink :to="item.to" class="nav-menu-sidebar__item-link" nav-menu>
                                    <component :is="item.icon"></component>
                                    <span class="nav-menu-sidebar__item-label">
                                        {{ item.label }}
                                        <span v-show="item.counter" class="nav-menu-sidebar__item-label-counter">{{
                                            item.counter
                                        }}</span>
                                    </span>
                                </RouterLink>
                                <div class="nav-menu-sidebar__devider" v-show="index === 3"></div>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
            <div class="nav-menu-sidebar__user-list">
                <div class="nav-menu-sidebar__item-link-user-details" v-show="toggleDetailsStatus">
                    <UserDetails :account="$_account" v-show="toggleDetailsStatus"></UserDetails>
                </div>
                <div class="nav-menu-sidebar__item-link" @click.stop="showDetails">
                    <UserCard :account="$_account" />
                </div>
            </div>
        </div>
        <nav class="nav-menu-tabbar">
            <template v-for="(item, index) in mobileItems">
                <RouterLink v-if="item.to" :key="index" :class="item.class" :to="item.to" class="nav-menu-tabbar__item-link" nav-menu>
                    <component :is="item.icon"></component>
                    <span class="nav-menu-tabbar__item-label">
                        {{ item.label }}
                        <span v-show="item.counter" class="nav-menu-tabbar__item-label-counter">{{
                            item.counter
                        }}</span>
                    </span>
                </RouterLink>
                <VButton v-else :key="index" class="nav-menu-tabbar__item-link" @click="item.onClick">
                    <component :is="item.icon"></component>
                    <span class="nav-menu-tabbar__item-label">{{ item.label }}</span>
                </VButton>
            </template>
        </nav>
    </div>
</template>
<script>
// Components
import VButton from '../../components/VButton/VButton.vue';
import UserCard from '@/views/components/VUser/UserCard.vue';
import UserDetails from '@/views/components/VUser/UserDetails.vue';
import ModalNames from '@/js/enums/ModalNames';

//SVG
import IncomingRequestsSVG from '@/images/incoming_requests.svg';
import OutcomingRequestsSVG from '@/images/outcoming_requests.svg';
import ArchiveSVG from '@/images/archive.svg';
import AllRequestsSVG from '@/images/all_requests.svg';
import SettingsSVG from '@/images/settings.svg';
import LogoutSVG from '@/images/logout.svg';
import DotsSVG from '@/images/dots-menu-horizontal.svg';
import PlusSVG from '@/images/plus.svg';
import TimesSvg from '@/images/times.svg';

// Other
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import oidc from '../../../vue-oidc-client';

const Workspace = namespace('workspace');
const widthMediaQuery = window.matchMedia('(max-width: 800px)');

@Component({
    components: {
        VButton,
        UserCard,
        UserDetails,
        SettingsSVG,
        LogoutSVG,
        DotsSVG,
        PlusSVG,
        TimesSvg,
    },
})
class NavMenu extends Vue {
    @Workspace.State menuInfo;
    @Workspace.State accessSecurityGroups;

    toggleDetailsStatus = false;
    isAdaptive = widthMediaQuery.matches ? true: false;
    isAdaptiveMenuOpen = false;

    logout() {
        oidc.signOut();
    }

    get items() {
        const items = [
            {
                to: {
                    name: 'request',
                    params: {
                        method: 'inbox',
                    },
                },
                icon: IncomingRequestsSVG,
                label: 'Входящие',
                counter: this.menuInfo?.inbox ?? 0,
            },
            {
                to: {
                    name: 'request',
                    params: {
                        method: 'outbox',
                    },
                },
                icon: OutcomingRequestsSVG,
                label: 'Исходящие',
                counter: this.menuInfo?.outbox ?? 0,
            },
            {
                to: {
                    name: 'request',
                    params: {
                        method: 'archive',
                    },
                },
                icon: ArchiveSVG,
                label: 'Архив',
            },
        ];

        if (this.$_isExecutor || this.$_isAdmin) {
            items.push({
                to: {
                    name: 'request',
                    params: {
                        method: 'store',
                    },
                },
                icon: AllRequestsSVG,
                label: 'Всe обращения',
                class: 'nav-menu__item_space-top',
            });
        }

        const groups = this.accessSecurityGroups ?? {};

        if (groups) {
            let groupKeys = [];

            if (this.menuInfo?.groups) {
                groupKeys = Object.keys(this.menuInfo.groups);
            }

            groups.forEach((group, index) => {
                let counter = 0;

                if (groupKeys.find((item) => item == group.id)) {
                    counter = this.menuInfo.groups[group.id];
                }

                const item = {
                    to: {
                        name: 'request',
                        params: {
                            method: group.id,
                        },
                    },
                    icon: AllRequestsSVG,
                    label: group.name,
                    counter: counter,
                    class: index === 0 ? 'nav-menu__item_space-top' : '',
                };

                items.push(item);
            });
        }

        return items;
    }

    get mobileItems() {
        const mobileItems = [
            {
                to: {
                    name: 'request',
                    params: {
                        method: 'inbox',
                    },
                },
                icon: IncomingRequestsSVG,
                label: 'Входящие',
                counter: this.menuInfo?.inbox ?? 0,
            },
            {
                to: {
                    name: 'request',
                    params: {
                        method: 'outbox',
                    },
                },
                icon: OutcomingRequestsSVG,
                label: 'Исходящие',
                counter: this.menuInfo?.outbox ?? 0,
            },
            {
                icon: PlusSVG,
                label: 'Создать',
                onClick: this.showRequestCreateModal,
            },
        ];

        if (this.$_isExecutor || this.$_isAdmin) {
            mobileItems.push({
                to: {
                    name: 'request',
                    params: {
                        method: 'store',
                    },
                },
                icon: AllRequestsSVG,
                label: 'Всe обращения',
                class: 'nav-menu__item_space-top',
            });
        }

        mobileItems.push(
            {
                icon: DotsSVG,
                label: 'Меню',
                onClick: this.openMenu,
            },
        )

        return mobileItems;
    }

    async showRequestCreateModal() {
        const request = await this.$showModal(ModalNames.REQUEST_CREATE);

        this.$router.push({
            name: 'request',
            params: {
                method: 'outbox',
                id: request.id,
            },
        });
    }

    toggleDetails() {
        this.toggleDetailsStatus = !this.toggleDetailsStatus;
    }

    showDetails() {
        if (!this.toggleDetailsStatus) this.toggleDetailsStatus = true;
    }

    hideDetails() {
        if (this.toggleDetailsStatus) this.toggleDetailsStatus = false;
    }

    openMenu(){
        this.isAdaptiveMenuOpen = true;
    }

    closeMenu(){
        this.isAdaptiveMenuOpen = false;
    }

    updateStyles() {
        this.isAdaptive = widthMediaQuery.matches ? true: false;
    }

    mounted() {
        document.addEventListener('click', this.hideDetails);
        widthMediaQuery.addEventListener('change', this.updateStyles);
    }

    destroyed() {
        document.removeEventListener('click', this.hideDetails);
        widthMediaQuery.removeEventListener('change', this.updateStyles);
    }
}

export default NavMenu;
</script>
<style lang="scss">
.router-link-active[nav-menu] {
    background: #f1f6fe;
}

::-webkit-scrollbar {
    width: 7px;
    border-radius: 12px;
}
::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 12px;
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}
.nav-menu{
    height: 100%;
    width: 100%;
}
.nav-menu-tabbar{
    display: flex;
    position: fixed;
    justify-content: space-around;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3.75rem;
    justify-content: center;
    gap: .375rem;
    padding: 0 .375rem;
    box-shadow: 0 0 0.6875rem 0 rgba(0, 0, 0, 0.09);

    &__item-link{
        display: flex;
        width: 20%;
        padding: .5rem 0;
        flex-direction: column;
        overflow: hidden;
        position: relative;
        border-radius: 10px;
        margin: 5px;
        font-size: 0.625rem;
        justify-content: center;
        align-items: center;
        box-shadow: none;
        svg{
            width: 1.2rem;
            height: 1.2rem;
            margin: 0 auto;
            color: #484848;
        }
    }
    &__item-label{
        width: 100%;
        line-height: 1.4rem;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 100%;
        text-align: center;
        padding: .4rem;
        &-counter{
            position: absolute;
            right: 0;
            top: 0;
            margin: 5px 10px 0 0;
            background-color: #00a55a;
            border-radius: 12px;
            padding: 3px 5px;
            color: #ffffff;
            font-size: 10px;
        }
    }
}

.nav-menu-sidebar{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 101;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    justify-content: space-between;
    display: flex;
    flex-direction: column;
    &__list {
        max-height: 55vh;
        margin: 0 10px;
    }

    &__header {
        margin: 0 10px 14px 10px;
        .button {
            font-size: 16px;
            border-radius: 10px;
        }
    }

    &__item {
        width: 100%;
        height: auto;
        min-height: 40px;
        margin-bottom: 4px;
        font-size: 1rem;
        color: var(--text-black);
    }

    &__item-link {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        min-height: 40px;
        padding: 0 16px;
        border-radius: 10px;
        cursor: pointer;
        position: relative;

        & svg {
            width: 24px;
            height: 24px;
            color: #484848;
        }
        &:hover {
            background: #f8f8f8;
        }

        .user-card {
            height: auto;
            width: inherit;
            display: flex;
            justify-content: center;
            &__label {
                white-space: nowrap;
                overflow: hidden;
                text-align: center;
            }
            &__label-name {
                font-size: 1rem;
            }
        }

        &-user-details {
            position: absolute;
        }
    }

    &__item-label {
        margin-left: 8px;
        display: flex;
        align-items: center;

        &-counter {
            position: absolute;
            right: 0;
            margin-right: 10px;
            background-color: #00a55a;
            border-radius: 12px;
            padding: 0 7px;
            color: #ffffff;
            font-size: 13px;
        }
    }

    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 24px;
        .button_close {
            position: absolute;
            right: 2rem;
            fill: var(--modal-close-button-fill);
            width: 16px;
            height: 16px;
            padding:  10px 10px 10px 30px;
            margin: -5px;
            box-sizing: content-box;
            cursor: pointer;
            box-shadow: none;
        }
    }

    &__logo-src {
        display: block;
        width: 158px;
        height: 44px;
        background: url(~@/images/talan-logo.svg?url) center center no-repeat;
        background-size: 100%;
    }

    &__devider {
        width: auto;
        border-radius: 1px;
        display: flex;
        justify-content: center;
        margin: 6px 24px;
        border-bottom: 1px var(--border-column-color) solid;
    }

    &__user-list {
        margin: 0px 10px 10px 10px;
    }
}

@media(min-width: $media-md){
    .nav-menu-tabbar{
        display: none;
    }
    .nav-menu-sidebar{
        position: static;
        &__logo {
            .button_close{
                display: none;
            }
        }
    }
}
@media(max-width: $mobile-breakpoint){
    .nav-menu-tabbar__item-label{
        display: none;
    }
}
</style>
