<template>
    <div class="details">
        <div class="details__user-card" @click.stop>
            <UserCard :account="account" />
        </div>
        <div class="details__devider"></div>

        <ul class="details__rows">
            <li class="details__row">
                <div class="details__link" @click="toggleSnow" v-if="isWinter">
                    <SnowflakeIcon class="details__icon"></SnowflakeIcon>

                    <span class="details__text">{{ isSnowEnabled ? 'Выключить снег' : 'Включить снег'}}</span>
                </div>
            </li>
            <li class="details__row" v-if="$_isAdmin">
                <RouterLink :to="{ name: 'admin-panel.users' }" class="details__link">
                    <SettingsSvg class="details__icon"></SettingsSvg>

                    <span class="details__text">Админ панель</span>
                </RouterLink>
            </li>

            <li class="details__row" @click="logout">
                <div class="details__link">
                    <ExitSvg class="details__icon"></ExitSvg>

                    <span class="details__text">Выйти</span>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
// Svg
import ExitSvg from '@/images/exit.svg';
import SettingsSvg from '@/images/settings.svg';
import SnowflakeIcon from 'vue-material-design-icons/Snowflake';

// Components
import UserCard from '@/views/components/VUser/UserCard.vue';

// Other
import { Vue, Component, Prop } from 'vue-property-decorator';
import oidc from '../../../vue-oidc-client';

@Component({
    components: {
        ExitSvg,
        SettingsSvg,
        SnowflakeIcon,

        UserCard,
    },
})
class UserDerails extends Vue {
    @Prop(Object) account;

    isSnowEnabled = true;
    date = new Date();


    get isWinter() {
        const day = this.date.getDate();
        const month = this.date.getMonth();

        return (month >= 11 && day >= 0) || (month <= 0 && day <= 14);
    }

    toggleSnow() {
        this.isSnowEnabled = !this.isSnowEnabled;
        this.$bus.emit('toggleSnow', this.isSnowEnabled);
    }

    logout() {
        oidc.signOut();
    }

    created(){
        this.isSnowEnabled = localStorage.getItem('toggleSnow') ? JSON.parse(localStorage.getItem('toggleSnow')) : true;
    }
}

export default UserDerails;
</script>
<style lang="scss">
.details {
    position: absolute;
    z-index: 1010;
    bottom: 0;
    left: 100%;
    width: 320px;
    border-radius: 10px;
    background-color: var(--color-white);
    box-shadow: 0px 2px 15px 3px rgba(44, 44, 44, 0.12);
    color: var(--text-black);

    &__user-card {
        display: block;
        padding: 8px 7px;
        color: initial;
        cursor: pointer;
        text-decoration: none;
    }

    &__rows {
        margin: 0;
        padding: 8px 0;
        list-style: none;
    }

    &__link {
        display: flex;
        color: var(--text-black);
        padding: 8px 15px;
        align-items: center;
        text-decoration: none;
        cursor: pointer;
        border-radius: 10px;
        margin: 0 8px;
        min-height: 40px;

        &:hover {
            background: #f8f8f8;
        }
    }

    &__icon {
        width: 20px;
        height: 20px;
    }

    &__text {
        padding: 0 12px;
        font: 400 0.9rem Roboto;
    }

    &__devider{
        width: auto;
        border-radius: 1px;
        display: flex;
        justify-content: center;
        margin: 0px 30px;
        border-bottom: 1px var(--border-column-color) solid;
    }
}
</style>
