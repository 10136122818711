<template>
    <VButton type="button" @click.prevent="showFileUploader" v-bind="$attrs">
        <slot></slot>

        <template v-slot:icon>
            <slot name="icon"></slot>
        </template>

        <form :action="endpoint" @click.stop @submit.prevent method="post">
            <input ref="input" @change="submitFiles" hidden multiple type="file" />
        </form>
    </VButton>
</template>
<script>
import { Vue, Component, Prop, Ref, Emit, Watch } from 'vue-property-decorator';
import http from '../../../js/http';
import FileUploaderErrorType from '@/js/enums/FileUploaderErrorType';

import VButton from '@/views/components/VButton/VButton.vue';
import FileUploaderErrorTitle from '../../../js/enums/FileUploaderErrorTitle';

@Component({
    components: {
        VButton,
    },
})
class FileUploader extends Vue {
    @Ref() input;
    @Prop({ type: Array })
    value;
    @Prop({ type: String, default: '/file/upload' })
    endpoint;
    @Prop({ type: Number, default: 20971520 })
    maxFileSize;
    @Prop({ type: Number, default: 10 })
    maxFileCount;
    @Prop({
        type: Array,
        default: () => [],
    })
    allowedFileTypes;

    files = [];

    submitFiles(event) {
        const files = event.target ? event.target.files: event.files;
        Object.keys(files).forEach((key) => {
            const file = files[key];

            this.handleFile(file);
        });

        // Resets the input value.
        this.input.value = '';
    }

    handleFile(uploadable) {
        if (!this.checkAllowedFileCount(this.files))
            return this.appendErrorFile(uploadable, FileUploaderErrorType.NOT_ALLOWED_FILE_COUNT);
        if (!this.checkAllowedFileSize(uploadable))
            return this.appendErrorFile(uploadable, FileUploaderErrorType.NOT_ALLOWED_FILE_SIZE);
        if (!this.checkAllowedFileType(uploadable))
            return this.appendErrorFile(uploadable, FileUploaderErrorType.NOT_ALLOWED_FILE_TYPE);

        const context = new FormData();
        context.append('files', uploadable);
        const file = this.appendUploadFile(uploadable);

        if (file.type.match(/^image\/*/)) {
            this.readerDateURL(file, uploadable);
        }

        this.uploadFile(context, file);
    }

    to(obj, key, value, time = 1000, step = 25) {
        let count = 0;
        const interval = setInterval(() => {
            count++;
            obj[key] = Math.round(value * ((step / time) * count));

            if (count * step === time) {
                clearInterval(interval);
            }
        }, step);
    }

    @Emit('input')
    uploadFile(context, file) {
        http.post(this.endpoint, context, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
                const total = progressEvent.total;
                const loaded = progressEvent.loaded;

                let progress = 0;

                // If value more than total then return max value.
                if (loaded > total) {
                    progress = 100;
                } else if (total === 0) {
                    progress = 0;
                } else {
                    progress = Math.round((loaded * 100) / total);
                }

                this.to(file, 'progress', progress);
            },
        })
            .then(({ data: uploadedFiles }) => {
                file.id = uploadedFiles[0].id;
                file.isUploading = false;
            })
            .catch(() => {
                file.error = 'Не удалось загрузить файл';
                file.errorMessage = 'Не удалось загрузить файл';
            })
            .finally(() => {
                file.isUploading = false;
            });

        return this.files;
    }

    @Emit('input')
    @Emit('append:file')
    appendErrorFile(fileContext, error) {
        const file = {
            error,
            errorMessage: FileUploaderErrorTitle[error],
            name: fileContext.name,
            size: fileContext.size,
            type: fileContext.type,
            isUploadedNow: true,
        };

        this.files.push(file);

        return this.files;
    }

    @Emit('append:file')
    appendUploadFile(file) {
        const stateFile = {
            id: -1,
            name: file.name,
            size: file.size,
            type: file.type,
            error: null,
            errorMessage: null,
            dataURL: '',
            progress: 0,
            isUploading: true,
        };
        this.files.push(stateFile);
        return stateFile;
    }

    readerDateURL(file, uploadable) {
        const reader = new FileReader();

        reader.onload = (e) => {
            file.dataURL = e.target.result;
        };

        reader.readAsDataURL(uploadable);
    }

    showFileUploader() {
        this.input.click();
    }

    checkAllowedFileCount(files) {
        return this.maxFileCount > files.length;
    }

    checkAllowedFileSize(file) {
        return this.maxFileSize > file.size;
    }

    checkAllowedFileType(file) {
        if (this.allowedFileTypes.length === 0) return true;

        return this.allowedFileTypes.some((type) => file.type.match(type));
    }

    pasteBufferFile(event) {
        const items = Object.values((event.clipboardData || event.originalEvent.clipboardData).items);

        if (!Array.isArray(items)) return;

        items.forEach((item) => {
            if (item.kind === 'file') {
                var blob = item.getAsFile();
                var reader = new FileReader();
                reader.onload = ({ target: { result } }) => {
                    result = result.split(';', 2);
                    const type = result[0].split(':', 2)[1];
                    const content = result[1].split(',', 2)[1];

                    this.handleFile(this.base64toBlob(content, type));
                };
                reader.readAsDataURL(blob);
            }
        });
    }

    base64toBlob(content, type, sliceSize = 512) {
        const byteArrays = [];
        const byteCharacters = atob(content);

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        return new File(byteArrays, 'screenshot.png', { type });
    }

    created() {
        document.addEventListener('paste', this.pasteBufferFile);
    }

    destroyed() {
        document.removeEventListener('paste', this.pasteBufferFile);
    }

    @Watch('value')
    onUpdateValue(value) {
        this.files = value;
    }
}

export default FileUploader;
</script>
