<template>
    <Snowf
        :amount="random(30, 50)"
        :size="random(6, 10)"
        :speed="random(1, 2.5)"
        :wind="[-1, 0, 1][Math.round(random(0, 2))]"
        :opacity="0.8"
        :swing="1"
        :image="require('@/images/snow.svg?url')"
        :zIndex="2147483647"
        :resize="false"
        color="#fff"
        v-if="isWinter && isSnowEnabled"
    />
</template>
<script>
import Vue from 'vue';
import Snowf from 'vue-snowf';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
    components: {
        Snowf,
    },
})
class VSnowfall extends Vue {
    @Prop({ type: Boolean })
    isSnowEnabled;

    date = new Date();

    random(min, max) {
        return Math.random() * (max - min) + min;
    }

    get isWinter() {
        const day = this.date.getDate();
        const month = this.date.getMonth();

        return (month >= 11 && day >= 0) || (month <= 0 && day <= 14);
    }

    mounted() {
        if (!this.isWinter) {
            return;
        }

        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.head.appendChild(link);
        }
        link.href = link.href.replace(/favicon\.(ico|png)/, 'favicon-winter.ico');
    }
}

export default VSnowfall;
</script>
<style lang="scss">
.snowf-canvas {
    pointer-events: none;
}
</style>
